<template>
    <div class="content">
        <el-form inline :model="search" class="tb-form">
            <!-- <el-form-item label="品牌名称：">
                <el-select v-model="search.brandid">
                    <el-option :label="item.brandName" :value="item.id" v-for="item in brandOptions"
                               :key="item.id"></el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="商品名称：">
                <el-input v-model="search.name"></el-input>
            </el-form-item>
            <el-form-item label="排序方式：">
                <el-select v-model="search.sort">
                    <el-option :label="item.label" :value="item.id" v-for="item in sortOptions"
                               :key="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="状态：">
                <el-select v-model="search.status">
                    <el-option :label="item.label" :value="item.id" v-for="item in statusOptions"
                               :key="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="分类：">
                <el-cascader :options="categoryOptions" v-model="search.categroy"></el-cascader>
            </el-form-item>
            <el-form-item label="是否置顶：">
                <el-select v-model="search.isSticky">
                    <el-option :label="item.label" :value="item.id" v-for="item in stickyOptions"
                               :key="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="创建时间：">
                <el-date-picker
                        v-model="search.date"
                        type="daterange"
                        value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd"
                        :clearable="false"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label="分销比例">
              <el-input placeholder="最小比例" type="number" v-model="search.min_return_rate"></el-input>
              ~
              <el-input placeholder="最大比例" v-model="search.max_return_rate"></el-input>
            </el-form-item> -->
            <el-form-item>
                <el-button type="primary" @click="onSearch">搜索</el-button>
            </el-form-item>
        </el-form>
        <div class="add">
            <el-button class="tb-button" type="primary" @click="add">
                <i class="tb-icon-plus"/>添加商品
            </el-button>
        </div>
        <div class="tablebox">
            <el-table
                    :data="tableData"
                    class="tb-table"
                    @row-dblclick="toDetail"
            >
                <el-table-column prop="id" label="商品ID"/>
                <el-table-column label="商品名称" min-width="300px">
                    <template slot-scope="scope">
                        <el-popover
                                width="300"
                                placement="top"
                                trigger="hover"
                                :content="scope.row.product_name"
                        >
                            <div slot="reference" class="ellipsis-two">{{scope.row.product_name}}</div>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="category_name" label="分类"/>
                <el-table-column prop="marketPrice" label="原价"/>
                <el-table-column prop="salePrice" label="优惠价">
                    <template slot-scope="scope">
                        {{scope.row.salePrice + "-" + scope.row.maxPrice}}
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="return_rate" label="分销比例"></el-table-column> -->
                <el-table-column prop="createTime" label="创建时间" min-width="100px"/>
                <el-table-column prop="startTime" label="上架时间" min-width="100px"/>
                <el-table-column prop="updateTime" label="更新时间" min-width="100px"/>
                <el-table-column prop="last_modifier" label="最后修改人" min-width="100px"/>
                <el-table-column prop="statusname" label="商品状态"/>
                <el-table-column prop="inshelf" label="上架有效期" min-width="100px"/>
                <el-table-column prop="sort" label="排序"/>
                <el-table-column label="操作" width="160">
                    <template slot-scope="scope">
                        <span class="edit" @click="toTop(scope.row)">置顶</span>
                        <el-divider direction="vertical"></el-divider>
                        <router-link :to="`/product/publish?id=${scope.row.id}`" class="edit">编辑</router-link>
                        <el-divider direction="vertical"></el-divider>
                        <span class="edit" @click="deleteItem(scope.row)">删除</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                    background
                    class="tb-pagination"
                    @size-change="sizeChange"
                    layout="prev, pager, next, sizes"
                    :current-page.sync="page.no + 1"
                    :page-sizes="[10,20,50,100]"
                    :page-size="page.size"
                    :total="page.total"
                    @current-change="pageChange"
            />
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: 'ProductList',
        data() {
            return {
                search: {
                    brandid: 71,
                    name: '',
                    date: ["", ""],
                    sort: 1,
                    status: '',
                    categroy: '',
                    isSticky: '',
                    min_return_rate: '',
                    max_return_rate: ''
                },
                statusOptions: [
                    {
                        id: 0,
                        label: '已过期'
                    }, {
                        id: 1,
                        label: '未过期'
                    }
                ],
                sortOptions: [
                    {
                        id: 1,
                        label: '创建时间'
                    }, {
                        id: 2,
                        label: '生效时间'
                    }, {
                        id: 3,
                        label: '更新时间'
                    }
                ],
                stickyOptions: [
                    {
                        id: 0,
                        label: '否'
                    }, {
                        id: 1,
                        label: '是'
                    }
                ],
                tableData: [],
                page: {
                    no: 0,
                    size: 10,
                    total: 0
                }
            }
        },
        computed: mapState([
            'brandOptions',
            'categoryOptions'
        ]),
        mounted() {
            let path = this.$route.path
            if (path.indexOf('/') === 0) path = path.substring(1)
            const currentPage = {
                link: path,
                name: '商品列表'
            }
            this.$store.commit('changeRoute', currentPage)
            this.getList()
            this.$store.commit('setCategoryOptions')
        },
        methods: {
            async getList() {
                let {no, size} = this.page
                let {brandid, name, date, sort, status, categroy, isSticky} = this.search
                const category_id = Array.isArray(categroy) ? categroy[2] : ''
                const params = {
                    page_no: no,
                    page_size: size,
                    sort_by: sort,
                    name,
                    is_sticky: isSticky,
                    brand_id: brandid,
                    status,
                    category_id
                }
                if (this.search.min_return_rate) {
                  params.min_return_rate = this.search.min_return_rate*100
                }
                if (this.search.max_return_rate) {
                  params.max_return_rate = this.search.max_return_rate*100
                }
                if (date[0] !== "" && date[1] !== "") {
                    params.start_time = date[0] + ' 00:00:00'
                    params.end_time = date[1] + ' 23:59:59'
                }
                const url = 'admin/product/list'
                const data = await this.$https.get(url, {params})
                if (!data) return
                data.list.forEach(item => {
                    item.createTime = this.$dayjs(item.created_at).format('YYYY-MM-DD')
                    item.startTime = this.$dayjs(item.start_time).format('YYYY-MM-DD')
                    item.updateTime = item.updated_at ? this.$dayjs(item.updated_at).format('YYYY-MM-DD') : ''
                    item.inshelf = this.$dayjs(item.start_time).isBefore(this.$dayjs()) && !this.$dayjs(item.end_time).isBefore(this.$dayjs()) ? '上架' : '下架'
                    item.marketPrice = this.$np.divide(item.market_price, 100)
                    item.salePrice = this.$np.divide(item.sale_price, 100)
                    item.return_rate = (item.return_rate ? this.$np.divide(item.return_rate, 100) : '--') + '%'
                    if (item.max_price) {
                       item.maxPrice = this.$np.divide(item.max_price, 100)
                    } else {
                        item.maxPrice = '-'
                    }
                    item.statusname = this.statusOptions[item.status].label
                })
                this.page.total = data.total_count
                this.tableData = data.list
            },
            onSearch() {
                this.page.no = 0
                this.getList()
            },
            add() {
                this.$router.push('/product/add')
            },
            async toTop(product) {
                const detailUrl = `admin/product/detail?id=${product.id}`
                const detail = await this.$https.get(detailUrl)
                if (!detail) return
                const params = Object.assign({}, detail, {is_sticky: 1})
                const editUrl = `admin/product/edit`
                const isTop = await this.$https.post(editUrl, params)
                if (isTop) this.getList()
            },
            deleteItem(product) {
                this.$confirm('是否删除此商品?', '', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const detailUrl = `admin/product/detail?id=${product.id}`
                    const detail = await this.$https.get(detailUrl)
                    if (!detail) return
                    const params = Object.assign({}, detail, {status: -1})
                    const editUrl = `admin/product/edit`
                    const isDelete = await this.$https.post(editUrl, params)
                    if (isDelete) {
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        })
                        this.getList()
                    }
                }).catch(() => {
                })
            },
            pageChange(no) {
                this.page.no = no - 1
                this.getList()
            },
            sizeChange(val) {
                this.page.no = 0
                this.page.size = val
                this.getList()
            },
            toDetail({id}) {
                this.$router.push('/product/publish?id=' + id)
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
